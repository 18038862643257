import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, navigate } from 'gatsby';
import { Layout, LWrap, LRestaurantContact } from '../../../components/_index';
import restaurantsMasterGet from '../../../utils/restaurantsMaster-get';

// markup
const SubPage = ({
  pageContext,
}: {
  pageContext: { dir: string; classification: string };
}) => {
  const data = restaurantsMasterGet(pageContext.classification);
  const linksrc =
    data.length > 0
      ? `/restaurants/${pageContext.dir}/${data[0].node.restaurantsMasterYrphId}/`
      : false;
  useEffect(() => {
    if (linksrc) {
      window.location.replace(linksrc);
    }
  }, [linksrc])

  if (linksrc) {
    return (
      <></>
    )
  } else {
    return (
      <Layout>
        <section className="u_mt100">
          <LWrap>
            <p className="u_tac">現在フェアはありません</p>
          </LWrap>
        </section>
        <section className="l_sect">
          <LWrap>
            <ul className="c_noteList">
              <li>画像は全てイメージです。</li>
              <li>料金には消費税、サービス料が含まれます。</li>
              <li>料理の表示価格は、特別な記載がない限り1名様分の料金です。</li>
              <li>
                テイクアウト商品の料金には消費税（8％）のみ含まれます。コフレ店内をご利用の場合は、サービス料を加算、消費税は10％となります。
              </li>
              <li>入荷状況によりメニュー内容に変更がある場合がございます。</li>
              <li>
                「神奈川県公共的施設における受動喫煙防止条例」に基づきレストラン・バーを全席禁煙とさせていただいております。
              </li>
              <li>
                ご入店の際は、タンクトップ・短パン・ビーチサンダルなどはご遠慮いただいております。
              </li>
            </ul>
          </LWrap>
        </section>
        <LRestaurantContact />
      </Layout>
    );
  }
};

export default SubPage;
